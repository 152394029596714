<script setup>
import { useStore } from 'vuex'
import UserArea from '~/components/menu/UserArea'
import CartCounter from '~/components/shopCart/CartCounter'
import ChevronIcon from '~/assets/icons/chevron-down.svg'

const store = useStore()
const isAuthenticated = computed(() => store.getters.isAuthenticated)
</script>

<template>
  <div class="header-menu d-flex justify-content-end align-items-center pr-0">
    <nav class="menu-items d-none d-lg-flex align-self-center">
      <div class="dropdown position-relative d-inline">
        <BaseButton class="menu-links reset-btn-styling ml-8" variant="naked">
          <span>{{ $label('settings.topNavItems.books') }}</span>
          <ChevronIcon class="chevron" />
        </BaseButton>

        <div class="dropdown-content">
          <NuxtLink to="/boeken" class="menu-links d-block mb-8">
            {{ $label('settings.topNavItems.currentSelection') }}
          </NuxtLink>
          <NuxtLink to="/archief" class="menu-links d-block">
            {{ $label('settings.topNavItems.archive') }}
          </NuxtLink>
        </div>
      </div>

      <NuxtLink to="/shop" class="menu-links">
        {{ $label('settings.topNavItems.shop') }}
      </NuxtLink>
      <NuxtLink to="/promo/verzilver" class="menu-links">
        {{ $label('settings.topNavItems.redeem') }}
      </NuxtLink>
      <NuxtLink to="/help" class="menu-links">
        {{ $label('settings.topNavItems.help') }}
      </NuxtLink>
    </nav>

    <div class="header-menu__vline d-none d-lg-block"></div>
    <CartCounter v-if="isAuthenticated" white-variant />

    <UserArea />
  </div>
</template>

<style lang="scss" scoped>
.header-menu__vline {
  height: 32px;
  margin: 0 32px;
  width: 1px;
  color: $mid-grey;
  background-color: $mid-grey;
}

a {
  margin-left: 32px;

  &:hover {
    text-decoration: underline;
    color: $black;
  }
}

.chevron {
  margin-left: 8px;
  fill: $black;
  transition: transform 0.3s ease-out;
}

button.menu-links {
  text-decoration: none;
  vertical-align: baseline;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $white;
  min-width: 182px;
  z-index: 3;
  padding: 16px;

  .menu-links {
    margin-left: 0;
    font-weight: normal;
  }
}

.dropdown:hover {
  .dropdown-content {
    display: block;
  }

  .chevron {
    transform: rotate(-180deg);
    margin-bottom: 3px;
  }
}
</style>
